import {PixelRatio, StyleSheet, Text, View} from "react-native";

function HelpView({marginTop = 0}){
    return (
        <View style={[styles.helpWrapper,{marginTop: PixelRatio.getPixelSizeForLayoutSize(marginTop)}]}>
            <Text style={styles.helpTitle}>☎이용문의 : 031-8059-5680</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    helpWrapper: {
        flex: 1,
        alignItems: 'center',
    },
    helpTitle: {
        color: '#DD7451',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '500',
        lineHeight: '120%',
        textAlign: 'center',
    },
    helpSubject: {
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '400',
        lineHeight: '160%',
        textAlign: 'center',
    }
})

export default HelpView