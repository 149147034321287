import {Image, PixelRatio, ScrollView, StyleSheet, Text, View} from "react-native";
import help_text from '../../../assets/images/plogging_manual.svg'

export const helpText =
            <Image source={help_text} style={{
                width: '100vw',
                height: '100vh',
            }}
                   resizeMode={'stretch'}
            />



