import {Image, PixelRatio, Pressable, StyleSheet, Text, View} from "react-native";
import {useScreenshot, createFileName} from 'use-react-screenshot'
import reward_exam from '../assets/images/frame/exam_frame.svg'
import reward_animal_1 from '../assets/images/reward_animal_1.svg'
import reward_animal_2 from '../assets/images/reward_animal_2.svg'
import change_ from '../assets/images/reward/change_frame.svg'
import screenshot_ from '../assets/images/reward/screenshot_frame.svg'
import reverse_ from '../assets/images/reward/reverse_frame.svg'
import TopMenu from "../components/public/TopMenu.tsx";
import {useEffect, useRef, useState} from "react";
import {client} from "../api/client.ts";
import {useMutation} from "@tanstack/react-query";
import {requests, responses, responseWrapper} from "../api/Entity.ts";
import {urls} from "../api/Url.ts";
import * as moment from "moment/moment";
import CryptoJS from 'crypto-js'
import MainButton, {ButtonType} from "../components/public/MainButton.tsx";
import CameraPage from "../components/camera/CameraPage.tsx";
import PopupModal, {IconType, PopupType} from "../components/public/PopupModal.tsx";

function Reward({navigation, route}){

    const [state, setState] = useState(0)
    const [frameNumber, setFrameNumber] = useState(0)

    const [point, setPoint] = useState(0)
    const [isTakeScreen, setIsTakeScreen] = useState(true)
    const [fileName, setFileName] = useState('')
    const [isCameraError, setIsCameraError] = useState(false)
    const [picturedScreen, setPicturedScreen] = useState(false)
    const [uploadedFile, setUploadedFile] = useState(false)
    const [reversedCamera, setReversedCamera] = useState(false)

    const [uploadSuccessVisible, setUploadSuccessVisible] = useState(false)

    const userInstance = useMutation({
        mutationFn: ()=> client.post<responseWrapper<responses['whoami']>>(urls['whoami']),
        onSuccess: ({data})=> {
            if(data.status) {

                const date = moment(Date.now()).format('YYMMDD')
                const id = data.data.id
                const name = data.data.name


                const file_name = `${date}_${name}_${id}`

                setFileName(file_name)

            }
        }
    })

    useEffect(() => {
        userInstance.mutate()
        findInstance.mutate()
    }, []);

    const findInstance = useMutation({
        mutationFn: ()=> client.post<responseWrapper<responses['plogging_find']>>(urls['plogging_find']),
        onSuccess: ({data})=> {
            if(data.status) {
                setPoint(data.data.pointList.length)

                const last = moment(data.data.lastDate).format('YY/MM/DD HH:mm')
                const reg = moment(data.data.regDate).format('YY/MM/DD HH:mm')
                const end = moment(data.data.endDate).format('YY/MM/DD HH:mm')

                //alert(`${reg} \n  ${last} \n ${end} \n  ${data.data.isEnd}`)
                if(data.data.isFileUploaded) {
                    setUploadSuccessVisible(true)
                }
            }
        },
    })

    const eventInstance = useMutation({
        mutationFn: ()=> client.post<responseWrapper<number>>(urls['isEvent']),
        onSuccess: ({data})=> {
            if (data.status) {
                if (data.data === 0 || data.data === 2) {
                    navigation.push('main')
                }else if (data.data === 1) {
                    navigation.push('eventPage')
                }

                setUploadSuccessVisible(false)
            }
        }
    })

    const [screen, takeScreen] = useScreenshot({
        type: 'image/jpeg',
        quality: 0.5,
    })
    const ref = useRef();

    const getScreen = ()=>takeScreen(ref.current)
    const [isFrontCamera, setIsFrontCamera] = useState(true)

    return (
        <View style={styles.wrapper}>
            <PopupModal visible={uploadSuccessVisible} setVisible={setUploadSuccessVisible}
                        text={(
                            <Text style={{color: '#666565'}}>
                                오늘 봉사가 정상적으로{'\n'}등록되었습니다.{'\n'}내일이 되면 다음 봉사활동을{'\n'}다시 참여할 수 있어요
                            </Text>
                        )}
                        type={PopupType.default}
                        buttonText={'처음으로 돌아가기'}
                        action={()=>{
                            eventInstance.mutate()
                        }}
            />
            <PopupModal
                visible={isCameraError}
                setVisible={setIsCameraError}
                text={'GPS 및 카메라 이용에\n동의해 주세요. 미 동의시\n서비스 이용이 불가능합니다.'}
                type={PopupType.default}
                action={()=>{
                    navigation.push('main')
                    setIsCameraError(false)
                }}
                iconType={IconType.gps}
            />
            <TopMenu navigation={ navigation } route={ route } userVisible={false}/>
            <View style={styles.titleWrapper}>
                <Text style={styles.titleText}>{
                    isTakeScreen?
                    '오늘 활동 사진 올리고\n봉사 인증과 완주증 받기' :
                        '오늘, 우리를 지켜줘서\n고마워요!'
                }</Text>
                <Text style={styles.subText}>
                    {isTakeScreen&&'* 당일 사진이 아닌 경우 봉사점수가 인정되지 않습니다.'}
                </Text>
                {
                    !isTakeScreen&&
                    (<>
                        <Image source={reward_animal_1} style={styles.animal1}/>
                        <Image source={reward_animal_2} style={styles.animal2}/>
                    </>)
                }
                {state===0&&(<Text style={styles.helpText}>{'<완주증 예시>'}</Text>)}
            </View>


            <View style={{alignItems: 'center', gap: PixelRatio.getPixelSizeForLayoutSize(11)}}>
                {
                    state===0 && (
                        <View style={styles.photoWrapper}>
                            <Image source={reward_exam} style={styles.frame} resizeMode={'stretch'} />
                        </View>)
                }
                {
                    state===1 && (
                        <View style={styles.photoWrapper} ref={ref}>
                            <CameraPage ploggingPont={point} toggleFacingMode={isFrontCamera}
                                        setErrorPopup={setIsCameraError} frameNumber={frameNumber}/>
                        </View>
                    )
                }
                {
                    state===2 && (
                        <View style={styles.photoWrapper}>
                            <Image source={screen} style={[styles.frame,]} resizeMode={'stretch'}/>
                        </View>
                    )
                }

            </View>

            {
                state===0&&(
                    <View>
                        <MainButton text={'인증사진 찍기'} type={ButtonType.default} action={()=>{setState(1)}}/>
                    </View>
                )
            }
            {
                state===1&&(
                    <View style={{flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                        <Pressable
                            onPress={()=>{
                                if (frameNumber + 1 >= 5) {
                                    setFrameNumber(0)
                                }else {
                                    setFrameNumber(frameNumber + 1)
                                }

                            }}>
                            <Image source={change_} style={[styles.pictureButton_s_Wrapper,{backgroundColor: '#469046'}]}/>
                            <Text style={[styles.pictureButton_s_Text, {color: '#469046'}]}>프레임 교체</Text>
                        </Pressable>
                        <Pressable onPress={()=>{
                            if(!picturedScreen) {
                                setPicturedScreen(true)
                                getScreen()
                                setState(2)
                            }
                        }}>
                            <Image source={screenshot_} style={styles.pictureButton_l_Wrapper}/>
                        </Pressable>
                        <Pressable
                            onPress={()=>{
                                if(!reversedCamera) {
                                    setIsFrontCamera(!isFrontCamera)
                                    setReversedCamera(true)
                                    setTimeout(()=>{setReversedCamera(false)},800)
                                }

                            }}>
                            <Image source={reverse_} style={[styles.pictureButton_s_Wrapper,{backgroundColor: '#3C7FB3'}]}/>
                            <Text style={[styles.pictureButton_s_Text, {color: '#3C7FB3'}]}>카메라 전환</Text>
                        </Pressable>

                    </View>
                )
            }
            {
                state===2&&(
                    <View style={{gap: PixelRatio.getPixelSizeForLayoutSize(12)}}>
                        <Text style={styles.resultHelpText}>
                            {'봉사 완료 버튼을 누르면\n완주증은 자동으로 앨범에 저장됩니다.'}
                        </Text>
                        <View style={{flexDirection: 'row', gap: PixelRatio.getPixelSizeForLayoutSize(12)}}>
                            <View style={{flex: 0.6}}>
                                <MainButton text={'봉사완료'} action={()=>{
                                    if (!uploadedFile) {
                                        setUploadedFile(true)
                                        const form = new FormData()

                                        const blob = new Blob([screen])
                                        form.append('file', blob)
                                        const hash = CryptoJS.SHA256(screen)

                                        client.post<responseWrapper<any>>(urls['file_upload'],form,{
                                            headers: {
                                                "Content-Type": 'multipart/form-data',
                                                'hash': hash
                                            }
                                        }).then(({data})=>{
                                            if(data.status) {
                                                const a = document.createElement("a")
                                                a.href = screen
                                                a.download = createFileName('jpeg', fileName)
                                                a.click()
                                                a.remove()

                                                setUploadSuccessVisible(true)
                                                setUploadedFile(false)
                                            }else {
                                                if(data.errorcode===201){
                                                    alert('파일 업로드에 실패 했습니다.')
                                                    setUploadedFile(false)
                                                }

                                            }
                                        })
                                    }else {
                                        alert('파일 업로드 중입니다.')
                                    }

                                }}/>
                            </View>
                            <View style={{flex: 0.4}}>
                                <MainButton text={'다시찍기'} action={()=>{
                                    setState(1)
                                    setPicturedScreen(false)
                                }} type={ButtonType.alert}/>
                            </View>
                        </View>

                    </View>
                )
            }

        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100vw',
        height: 'auto',
        backgroundColor: '#FEFAED',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(35),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(35),
        gap: PixelRatio.getPixelSizeForLayoutSize(25),
    },
    titleWrapper: {
        gap: PixelRatio.getPixelSizeForLayoutSize(12),
        alignItems: 'center'
    },
    titleText: {
        color: '#469046',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(20),
        fontWeight: '700',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(25),
        textAlign: 'center'
    },
    subText: {
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '400',
        lineHeight: '160%',
        textAlign: 'center'
    },
    animal1: {
        position: 'absolute',
        top: PixelRatio.getPixelSizeForLayoutSize(7),
        width: PixelRatio.getPixelSizeForLayoutSize(39.92),
        height: PixelRatio.getPixelSizeForLayoutSize(67),
    },
    animal2: {
        position: 'absolute',
        right: PixelRatio.getPixelSizeForLayoutSize(-21.33),
        top: PixelRatio.getPixelSizeForLayoutSize(49),
        width: PixelRatio.getPixelSizeForLayoutSize(123.33),
        height: PixelRatio.getPixelSizeForLayoutSize(78),
    },
    photoWrapper: {
        width: PixelRatio.getPixelSizeForLayoutSize(300),
        height: PixelRatio.getPixelSizeForLayoutSize(428),
        alignItems: 'center',
        justifyContent: 'center',
    },
    frame: {
        width: '100%',
        height: undefined,
        aspectRatio: 1/1.43,
        zIndex: 1
    },
    helpText: {
        color: '#3C7FB3',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '800',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(8),
    },
    buttonWrapper:{
        width: '100%',
        backgroundColor: '#469046',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(16),
        paddingHorizontal:  PixelRatio.getPixelSizeForLayoutSize(19),
        alignItems: 'center',
        justifyContent: 'center'
    },
    retakeButtonColor: {
      backgroundColor: '#666565'
    },
    buttonTitleText: {
        color: '#FEFAED',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(18),
        fontWeight: '700',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(24)
    },
    buttonSubText: {
        color: '#FEFAED',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '400',
        lineHeight: '160%'
    },
    pictureButton_s_Wrapper: {
        width: PixelRatio.getPixelSizeForLayoutSize(80),
        height: PixelRatio.getPixelSizeForLayoutSize(80),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(40),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'red'
    },
    pictureButton_s_Text: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: PixelRatio.getPixelSizeForLayoutSize(-20),
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '700',
        textAlign: 'center'
    },
    pictureButton_l_Wrapper: {
        width: PixelRatio.getPixelSizeForLayoutSize(120),
        height: PixelRatio.getPixelSizeForLayoutSize(120),
    },
    pictureButton_l_Text: {
        position: 'absolute',
        bottom: PixelRatio.getPixelSizeForLayoutSize(-20),
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '700',
    },
    resultHelpText: {
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '800',
        textAlign: 'center'
    }

})

export default  Reward