import React, {useState} from "react";
import {Image, PixelRatio, Pressable, StyleSheet, Text, View} from "react-native";
import logo from '../assets/images/logo_big.svg'
import TopMenu from "../components/public/TopMenu.tsx";
import InputLine from "../components/acount/InputLine.tsx";
import MainButton, {ButtonType} from "../components/public/MainButton.tsx";
import HelpView from "../components/public/HelpView.tsx";
import BottomView from "../components/public/BottomView.tsx";
import {useMutation} from "@tanstack/react-query";
import {requests, responses, responseWrapper} from "../api/Entity.ts";
import {addRefreshToken, addToken, client} from "../api/client.ts";
import {urls} from "../api/Url.ts";
import AsyncStorage from "@react-native-async-storage/async-storage";
import PopupModal, {IconType, PopupType} from "../components/public/PopupModal.tsx";

function Login({navigation, route}) {

    const [id, setId] = useState('')
    const [password, setPassword] = useState('')
    const [checkPassword, setCheckPassword] = useState(false)
    const [checkId, setCheckId] = useState(false)

    const loginInstance = useMutation({
        mutationFn: (req: requests['login'])=>client.post<responseWrapper<responses['login']>>(urls['login'],req),
        onSuccess: ({data})=> {
            if(data.status) {
                addToken(data.data.token)
                addRefreshToken(data.data.refreshToken)
                AsyncStorage.setItem('token', data.data.token).then()
                AsyncStorage.setItem('refreshToken', data.data.refreshToken).then()
                navigation.push('main')
            }else {
                if (data.errorcode === 101)
                    setCheckId(true)
                else if (data.errorcode === 102)
                    setCheckPassword(true)
            }
        }
    })

    return (
        <View style={styles.wrapper}>
            <PopupModal visible={checkId} setVisible={setCheckId} type={PopupType.default}
                        iconType={IconType.none} text={'아이디가\n존재하지 않습니다.'}/>
            <PopupModal visible={checkPassword} setVisible={setCheckPassword} type={PopupType.default}
                        iconType={IconType.none} text={'비밀번호를\n확인 해 주세요.'}/>
            <TopMenu navigation={navigation} route={route} userVisible={false}/>
            <View style={styles.contentWrapper}>
                <View style={styles.logoWrapper}>
                    <Image source={logo} style={styles.logo} resizeMode={'stretch'}/>
                </View>
                <View>
                    <InputLine subject={''} useSubject={false} text={id} setText={setId} hint={'아이디'}/>
                    <InputLine subject={''} useSubject={false} text={password} setText={setPassword} hint={'비밀번호'} isSecurity={true}/>
                </View>
                <View style={styles.buttonWrapper}>
                    <MainButton text={'로그인'} action={()=>{
                        const req: requests['login'] = {
                            id: id,
                            password: password
                        }

                        loginInstance.mutate(req)

                    }} type={ButtonType.default}/>

                    <View style={[styles.horizontalWrapper,{paddingVertical: PixelRatio.getPixelSizeForLayoutSize(10)}]}>
                        <Pressable onPress={()=>alert('준비중입니다.')}>
                            <Text style={styles.findText}>아이디 찾기</Text>
                        </Pressable>
                        <Text style={styles.findText}>/</Text>
                        <Pressable onPress={()=>alert('준비중입니다.')}>
                            <Text style={styles.findText}>비밀번호 재설정</Text>
                        </Pressable>
                    </View>

                    <View style={styles.horizontalWrapper}>
                        <View style={styles.separator}/>
                        <Text style={styles.separatorText}>또는</Text>
                        <View style={styles.separator}/>
                    </View>

                    <MainButton text={'회원가입'} action={()=>navigation.push('account')} type={ButtonType.other}/>
                </View>
            </View>
            <View style={{gap: PixelRatio.getPixelSizeForLayoutSize(37)}}>
                <HelpView marginTop={33}/>
                <BottomView/>
            </View>

        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100vw',
        minHeight: innerHeight,
        backgroundColor: '#FEFAED',
    },
    contentWrapper: {
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(45),
        gap: PixelRatio.getPixelSizeForLayoutSize(25)
    },
    logoWrapper: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: PixelRatio.getPixelSizeForLayoutSize(55),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(90),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(105),
    },
    logo: {
        width: 180,
        height: 196,
    },
    buttonWrapper: {
        gap: PixelRatio.getPixelSizeForLayoutSize(10),
    },
    horizontalWrapper: {
        flexDirection: 'row',
        gap: PixelRatio.getPixelSizeForLayoutSize(10),
        alignItems: 'center',
        justifyContent: 'center'
    },
    separator: {
        height: PixelRatio.getPixelSizeForLayoutSize(1),
        backgroundColor: '#999999',
        flex: 1,
    },
    separatorText: {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '800',
        color: '#666565',
    },
    findText: {
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '400'
    }
})

export default Login