import {StyleSheet, Text, View, TextInput, PixelRatio} from "react-native";
import {useEffect, useState} from "react";

function InputLine({subject, text, setText, hint='', addStar = false,
                       isSecurity = false, useSubject = true,
                       isPhone = false, useColor = false, isUse = true,
                       type = InputType.default
}){
    const [front, setFront] = useState('')
    const [middle, setMiddle] = useState('')
    const [back, setBack] = useState('')

    useEffect(() => {
        const full = `${front}${middle}${back}`

        if (text !== full && full.length >= 11) {
            setText(full)
        }
    }, [front,middle,back]);

    useEffect(() => {
        if(isPhone && text.length<=11) {
            const t = `${text}`
            setFront(t.substring(0,3))
            setMiddle(t.substring(3,7))
            setBack(t.substring(7, 11))
        }
    }, [text]);



    if (isPhone)

        return (
            <View style={styles.wrapper}>
                {useSubject && <Text style={styles.subject}>{subject}{addStar&&<Text style={styles.star}> *</Text>}</Text>}
                <View style={{flexDirection: 'row', alignItems: 'center', gap: PixelRatio.getPixelSizeForLayoutSize(9)}}>
                    <TextInput
                        style={[styles.inputText, {width: '30%'}]}
                        value={front}
                        onChangeText={(v)=> {
                            if (v.length<=3&&!isNaN(+v))
                                setFront(v)
                        }}
                        placeholderTextColor={'#666565'}
                        keyboardType={"number-pad"}
                    />
                    <Text>-</Text>
                    <TextInput
                        style={[styles.inputText, {width: '40%'}]}
                        value={middle}
                        onChangeText={(v)=>{
                            if (v.length<=4&&!isNaN(+v))
                                setMiddle(v)
                        }}
                        placeholderTextColor={'#666565'}
                        keyboardType={"number-pad"}
                    />
                    <Text>-</Text>
                    <TextInput
                        style={[styles.inputText, {width: '40%'}]}
                        value={back}
                        onChangeText={(v)=>{
                            if (v.length<=4&&!isNaN(+v))
                                setBack(v)
                        }}
                        placeholderTextColor={'#666565'}
                        keyboardType={"number-pad"}
                    />
                </View>
            </View>
        )
    return (
        <View style={styles.wrapper}>
            {useSubject && <Text style={[styles.subject, useColor && styles.star]}>
                {subject}{addStar&&<Text style={styles.star}> *</Text>}
            </Text>}
            <TextInput
                style={[styles.inputText, useColor && styles.star, !isUse&&{backgroundColor:'#EDEDED'}]}
                value={text}
                onChangeText={(v)=>{
                    if (type===InputType.birth) {
                        if(v.length<=8) {
                            if(isNaN(+v)) {
                                alert('숫자만 입력 가능합니다.')
                            }
                            else if((+v<19000000||+v>=29991231)&&v.length===8){
                                setText('')
                                alert('잘못된 생년월일입니다.')
                            }
                            else {
                                setText(v)
                            }
                        }
                    }else if(type===InputType.id) {
                        const reg = new RegExp(/^[a-z0-9_]*$/g)
                        if (reg.test(v))
                            setText(v)
                        else
                            alert('ID에는 영어 소문자, 숫자, 특수문자 \'_\'만 사용할 수 있습니다.')
                    }
                    else
                        setText(v)
                }}
                placeholder={hint}
                placeholderTextColor={useColor?'#DD7451':'#666565'}
                editable={isUse}
                secureTextEntry={isSecurity}
                keyboardType={type===InputType.birth&&"number-pad"}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        gap: PixelRatio.getPixelSizeForLayoutSize(3),
    },
    subject: {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '800',
        color: '#666565',
    },
    star: {
        color: '#DD7451',
    },
    inputText: {
        height: PixelRatio.getPixelSizeForLayoutSize(44),
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(0.7),
        borderColor: '#B2B2B2',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(12),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(11),
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '400',
        color: '#666565',
    }
})

export enum InputType  {
    default,
    id,
    password,
    birth,
    phone
}

export default InputLine