import {Image, Modal, PixelRatio, Pressable, ScrollView, StyleSheet, Text, View} from "react-native";
import x from '../../assets/images/x_.svg'
function TermsModal({visible, setVisible, subject= <Text></Text>}) {
    return(
        <Modal
            visible={visible}
            transparent={true}
        >
            <View style={styles.wrapper}>
                <View style={styles.contentWrapper}>
                    <View style={styles.topWrapper}>
                        <Pressable
                            style={styles.xWrapper}
                            onPress={()=>{setVisible(false)}}
                        >
                            <Image source={x} style={styles.x}/>
                        </Pressable>

                    </View>
                    <ScrollView  style={styles.contentTextWrapper}>
                        <View style={{alignItems: 'center'}}>
                            {subject}
                        </View>
                    </ScrollView>
                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#0009',
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(30),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(25),
    },
    contentWrapper: {
        width: '100%',
        height: '100%',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25);',
        padding: PixelRatio.getPixelSizeForLayoutSize(10),
        gap: PixelRatio.getPixelSizeForLayoutSize(28),
        backgroundColor: '#fff'
    },
    topWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: PixelRatio.getPixelSizeForLayoutSize(15)
    },
    xWrapper: {
        position: 'absolute',
        right: PixelRatio.getPixelSizeForLayoutSize(35),
    },
    x: {

        width: PixelRatio.getPixelSizeForLayoutSize(20),
        height: PixelRatio.getPixelSizeForLayoutSize(20)
    },
    contentTextWrapper: {
        flex: 1
    },
    bottomWrapper: {
        gap: PixelRatio.getPixelSizeForLayoutSize(12),
    }
})

export default TermsModal