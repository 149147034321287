import {Text} from "react-native";

export const alertText = <Text>이 곳은 화성호 횡단 플로깅 위치가 아닌 것 같아요!{'\n'}
    확인 후 다시 시도해 주세요.</Text>

export const accountMessage = (id, name, phone, birth)=>{
    return (<Text>
        {
            `아이디: ${id}\n이름: ${name}\n연락처: ${phone}\n생년월일: ${birth}\n
            \n`
        }
        <Text style={{fontWeight: '600'}}>
            정보가 정확한지 확인 후{'\n'}
            <Text style={{color: '#469046'}}>‘가입 확인’</Text> 버튼을 눌러주세요
        </Text>
    </Text>)
}